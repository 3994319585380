<template>
  <Page full>
    <Grid
      type="quote"
      title="Estimates"
      :multiple="true"
      :isMain="true"
      :showDefaultFilterPresets="true"
      :order="[['quote_time_last_modified', 'DESC']]"
      :filters="{
        quote_status: 'p',
        quote_state: '!lead'
      }"
      :filterText="{
        quote_status: 'Pending'
      }"
      :visible="[
        'quote_preview',
        'nextSteps',
        'quote_status',
        'quote_owner',
        'quote_time_created',
        'quote_time_last_modified',
        'quote_price_net'
      ]"
    />
  </Page>
</template>

<script>
import Grid from '@/components/ui/Grid/Grid.vue'
import PageMixin from '../mixins/Page'

export default {
  mixins: [PageMixin],
  components: {
    Grid
  }
}
</script>
